import { LANGUAGE } from "services/general";
import {
  Accounts,
  ActiveContractsIcon,
  ActiveHomeIcon,
  ActiveInve,
  ActiveSettingsIcon,
  ActiveSupport,
  ActiveUnitsIcon,
  ActiveUserIcon,
  ContractsIcon,
  HelpCenter,
  HomeIcon,
  Inventory,
  Missions,
  SettingsIcon,
  UnitsIcon,
  UserIcon,
} from "../components/shared/icons";

export const asideLinks = [
  {
    text: "الرئيسية",
    en_text: "Home",
    path: `/${LANGUAGE}/home`,
    icon: <HomeIcon />,
    activeIcon: <ActiveHomeIcon />,
    id: 95,
  },
  {
    text: "الصلاحيات",
    en_text: "Permissions",
    path: `/${LANGUAGE}/permissions`,
    icon: <UserIcon />,
    activeIcon: <ActiveUserIcon />,
    id: 88,
  },
  {
    text: "مدراء النظام",
    en_text: "System Administrators",
    path: `/${LANGUAGE}/system-administrators`,
    icon: <UserIcon />,
    activeIcon: <ActiveUserIcon />,
    id: 24,
  },
  {
    text: "العملاء",
    en_text: "Customers",
    path: `/${LANGUAGE}/customers`,
    icon: <UserIcon />,
    activeIcon: <ActiveUserIcon />,
    id: 9,
  },
  {
    text: "الموظفين",
    en_text: "Employees",
    path: `/${LANGUAGE}/employees`,
    icon: <UserIcon />,
    activeIcon: <ActiveUserIcon />,
    id: 57,
  },
  {
    text: "العقارات و المرافق",
    en_text: "Properties & Facilities",
    path: `/${LANGUAGE}/properties`,
    icon: <UnitsIcon />,
    activeIcon: <ActiveUnitsIcon />,
    id: 13,
  },
  {
    text: "الوحدات",
    en_text: "Units",
    path: `/${LANGUAGE}/units`,
    icon: <UnitsIcon />,
    activeIcon: <ActiveUnitsIcon />,
    id: 17,
  },
  {
    text: "رسائل الوحدات",
    en_text: "Units Messages",
    path: `/${LANGUAGE}/units-messages`,
    icon: <UnitsIcon />,
    activeIcon: <ActiveUnitsIcon />,
    id: 21,
  },
  {
    text: "المخازن",
    en_text: "Inventory",
    path: `/${LANGUAGE}/inventory`,
    icon: <Inventory />,
    activeIcon: <ActiveInve />,
    id: 69,
  },
  {
    text: "التقارير",
    en_text: "Reports",
    path: `/${LANGUAGE}/reports`,
    icon: <Inventory />,
    activeIcon: <ActiveInve />,
    id: 85,
  },
  {
    text: "الأرصدة و الحسابات",
    en_text: "Balances & Accounts",
    path: "/",
    icon: <Missions />,
    activeIcon: <Missions />,
    children: [
      {
        text: "البنوك",
        en_text: "Banks",
        path: `/${LANGUAGE}/banks`,
        id: 32,
      },
      {
        text: "السندات",
        en_text: "Bonds",
        path: `/${LANGUAGE}/sanadat`,
        id: 55,
      },
      {
        text: "القيود",
        en_text: "Entries",
        path: `/${LANGUAGE}/financial-entries`,
        id: 36,
      },

      {
        text: "الحسابات",
        en_text: "Financial Trees",
        path: `/${LANGUAGE}/financial-trees`,
        id: 65,
      },
    ],
  },
  {
    text: "المبيعات",
    en_text: "Sales",
    path: "/",
    icon: <Missions />,
    activeIcon: <Missions />,
    children: [
      {
        text: "فواتير المبيعات",
        en_text: "Invoices",
        path: `/${LANGUAGE}/sales-invoices`,
        id: 1,
      },
      {
        text: "اشعارات دائنة",
        en_text: "Credit Notes",
        path: `/${LANGUAGE}/sales-return-invoices/credit_note`,
        id: 100,
      },
      {
        text: " الفواتير المرتجعة ",
        en_text: "Refund Invoices",
        path: `/${LANGUAGE}/sales-return-invoices/s_refund`,
        id: 100,
      },
    ],
  },
  {
    text: "المشتريات",
    en_text: "Purchases",
    path: "/",
    icon: <Missions />,
    activeIcon: <Missions />,
    children: [
      {
        text: "فواتير الشراء",
        en_text: "Purchase Invoices",
        path: `/${LANGUAGE}/purchase-invoices`,
        id: 5,
      },
      {
        text: "طلب امر الشراء",
        en_text: "Purchase order request",
        path: `/${LANGUAGE}/purchase-order-request`,
        id: 108,
      },
      {
        text: "اشعارات مدينه",
        en_text: "Purchase return invoices",
        path: `/${LANGUAGE}/purchase-return-invoices/debit_note`,
        id: 104,
      },
      {
        text: " الفواتير المرتجعة ",
        en_text: "Refund Invoices",
        path: `/${LANGUAGE}/purchase-return-invoices/p_refund`,
        id: 104,
      },
    ],
  },

  {
    text: "العقود",
    en_text: "Contracts",
    path: `/${LANGUAGE}/contracts`,
    icon: <ContractsIcon />,
    activeIcon: <ActiveContractsIcon />,
    id: 40,
  },
  {
    text: "مركز المساعدة",
    en_text: "Support Center",
    path: `/${LANGUAGE}/support-center`,
    icon: <HelpCenter />,
    activeIcon: <ActiveSupport />,
    id: 96,
  },
  {
    text: "الإعدادات",
    en_text: "Settings",
    path: `/${LANGUAGE}/settings`,
    icon: <SettingsIcon />,
    activeIcon: <ActiveSettingsIcon />,
    id: 86,
  },
  {
    text: "قواعد البيانات",
    en_text: "Databases",
    icon: <Accounts />,
    activeIcon: <Accounts />,
    path: "/",
    children: [
      {
        text: "الخدمات",
        en_text: "Services",
        path: `/${LANGUAGE}/services`,
        id: 49,
      },
      {
        text: "الشركات",
        en_text: "Entities",
        path: `/${LANGUAGE}/entities`,
        id: 28,
      },
      {
        text: "قوالب شروط العقود",
        en_text: "Contract Conditions Templates",
        path: `/${LANGUAGE}/contracts-conditions`,
        id: 45,
      },
      {
        text: "الدرجه الوظيفيه",
        en_text: "Job Positions",
        path: `/${LANGUAGE}/employee-positions`,
        id: 61,
      },
      {
        text: "وحدات قياس المخزون",
        en_text: "Inventory units of measurement",
        path: `/${LANGUAGE}/inventory-types`,
        id: 73,
      },
      {
        text: "المهام",
        en_text: "Tasks",
        path: `/${LANGUAGE}/tasks`,
        id: 81,
      },
      {
        text: "الملاحظات",
        en_text: "Notes",
        path: `/${LANGUAGE}/notes`,
        id: 77,
      },
    ],
  },
];
